import React from 'react'
import styled from 'styled-components';
import StoreItem from './store-item';

export default function StoreList({ stores }) {
    return (
        <Grid>
            {stores.map((store) => (
                <StoreItem key={store.id} store={store} useHeader />
            ))}
        </Grid>
    )
}
const Grid = styled.div`
    display: grid;
    grid-gap: 15px;
	row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	/* max-width: 1200px; */
	margin: 0 auto;
	
    @media (max-width: 800px) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        grid-gap: 10px;
		row-gap: 15px;
    }

    @media (max-width: 650px) {
        grid-template-columns: repeat(1, minmax(150px, 1fr));
        grid-gap: 10px;
		row-gap: 15px;
    }
`;